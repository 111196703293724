<template>
  <Dialog :value="visible" @input="handleClose">
    <template #header> Edit {{ form.staffType }} Staff Cost Details </template>
    <template #body>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <DatePicker v-bind="inputProps" label="Date" v-model="form.date" />
          </v-col>
          <v-col cols="12" sm="6" v-if="showStartEndTime">
            <TimePicker v-bind="inputProps" label="Start Time" v-model="form.startTime" @input="updateHours(form)" />
          </v-col>
          <v-col cols="12" sm="6" v-if="showStartEndTime">
            <TimePicker v-bind="inputProps" label="End Time" v-model="form.endTime" @input="updateHours(form)" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :rules="[required, decimal]"
              label="Hours"
              v-bind="inputProps"
              v-model="form.hours"
            />
          </v-col>
          <v-col cols="12" sm="4" v-if="invoiceRate">
            <v-select
              label="District Wide Rate Type"
              :rules="[required]"
              v-bind="inputProps"
              v-model="form.districtRateType"
              :items="enumToArray(districtRateTypes)"
              @change="setHourlyRate({ form, staff: currentStaff[form.staffType], staffType: form.staffType })"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              label="Rate Type"
              :rules="[required]"
              v-bind="inputProps"
              v-model="form.rateType"
              :items="enumToArray(staffCostMap.rateType)"
              @change="setHourlyRate({ form, staff: currentStaff[form.staffType], staffType: form.staffType })"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :rules="[required, decimal]"
              label="Hourly Rate"
              v-bind="inputProps"
              v-model="form.hourlyRate"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              type="number"
              :rules="[required]"
              label="Break (minutes)"
              v-bind="inputProps"
              v-model="form.breaks"
            >
              <template #append-outer>
                <InvoiceDetailsBreakCalculator v-model="form.breaks" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <div class="text-right text-h5 font-weight-bold mt-4">Total Cost: ${{ computeCost(form) }}</div>
      </v-form>
    </template>
    <template #actions>
      <v-btn :loading="invoiceIsFetchingStaffCost" color="success" @click="saveForm">
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { inputProps, toUcWords } from '@/util';
import { Dialog } from '@/components/shared';
import { staffCostMap, enumToArray } from '@/util/enums';
import { required, decimal } from '@/util/rules';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import InvoiceDetailsBreakCalculator from '@/components/Invoice/details/InvoiceDetailsBreakCalculator';

export default {
  props: {
    details: { type: Object, default: () => ({}) },
    visible: { type: Boolean, default: false },
    updateHours: { type: Function, default: () => 0 },
    computeCost: { type: Function, default: () => 0 },
    setHourlyRate: { type: Function, default: () => 0 },
    invoiceRate: { type: Number, default: 0 },
    districtRateTypes: { type: Object, default: () => ({}) },
    currentStaff: { type: Object, default: () => ({}) },
    showStartEndTime: { type: Boolean, default: () => false },
    save: { type: Function, default: () => {} },
  },
  components: { Dialog, TimePicker, DatePicker, InvoiceDetailsBreakCalculator },
  data() {
    return {
      staffCostMap,
      inputProps,
      required,
      decimal,
      dialog: false,
      form: {
        date: null,
        startTime: null,
        endTime: null,
        hours: null,
        districtRateType: null,
        rateType: null,
        hourlyRate: null,
        breaks: null,
        id: null,
      },
      invoiceId: null,
    };
  },
  computed: {
    ...mapGetters('invoice', ['invoiceIsFetchingStaffCost']),
  },
  methods: {
    ...mapActions('invoice', ['saveInvoiceStaffCost']),
    enumToArray,
    handleClose() {
      this.$emit('update:visible', false);
    },
    setFormValues(value = null) {
      this.form.date = value || this.details.staffCostMap.date;
      this.form.startTime = value || this.details.staffCostMap.startTime;
      this.form.endTime = value || this.details.staffCostMap.endTime;
      this.form.hours = value || this.details.staffCostMap.hours;
      this.form.districtRateType = value || this.details.staffCostMap.districtRateType;
      this.form.rateType = value || this.details.staffCostMap.rateType;
      this.form.hourlyRate = value || this.details.staffCostMap.hourlyRate;
      this.form.breaks = value || this.details.staffCostMap.breaks;
      this.form.id = value || this.details.staffCostMap.id;
      this.form.staffType = value || toUcWords(this.details.staffCostMap.staffType);
    },
    saveForm() {
      this.save({ form: this.form, novalidate: true });
    },
  },
  mounted() {
    this.setFormValues();

    this.invoiceId = this.details.invoiceId;
    this.dialog = this.visible;
  },
};
</script>
