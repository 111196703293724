<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation>
    <v-card>
      <v-card-title>Search by {{ title }}</v-card-title>
      <v-card-text class="pt-5">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-subtitle-1">
              Enter the {{ title }}(s) you want to search for. Separate multiple {{ title }}s with a comma (","). You
              can include a range of {{ title }}s by using a hyphen ("-").
            </div>
            <div class="text-caption">Example: 1,4,7-10,15</div>
          </v-col>
        </v-row>
        <v-row justify="center" class="pb-4">
          <v-col cols="12" md="6">
            <v-text-field v-model="filterStr" outlined :label="`${title}s`"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="apply" :disabled="!filterStr.length"> Apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'IdPicker',
  inject: ['eventHub'],
  components: {},
  props: {
    title: String,
    filterType: String,
    filter: Object,
  },
  data() {
    return {
      dialog: false,
      filterStr: '',
    };
  },
  computed: {
    filterEvent() {
      if (this.filterType == 'trip') return 'addFilterTripRequests';
      else if (this.filterType == 'assignment') return 'addFilterAssignments';
      else if (this.filterType == 'invoice') return 'addFilterInvoice';
      else return '';
    },
  },
  methods: {
    apply() {
      if (this.filterStr.length)
        this.eventHub.$emit(this.filterEvent, {
          name: `${this.filter.name}(s)`,
          search: {
            field: this.filter.field,
            value: this.toArray(),
            text: `${this.filter.name}(s) ${this.filterStr}`,
          },
        });
      this.close();
    },
    toArray() {
      let ids = [];
      this.filterStr.split(',').forEach((e) => {
        let id = e.trim();
        if (Number(id)) ids.push(Number(id));
        else if (id.includes('-')) {
          for (let i = Number(id.split('-')[0]); i <= id.split('-')[1]; i++) ids.push(i);
        }
      });
      return ids;
    },
    close() {
      this.dialog = false;
      this.filterStr = '';
    },
  },
};
</script>

<style lang="scss"></style>
