<template>
  <v-card>
    <v-tabs show-arrows v-model="activeTab" class="tt-tabs">
      <v-tab dark v-for="tab in filteredTabs" :key="tab.name">{{ tab.name }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(tab, tabIndex) in filteredTabs" :key="tab.name">
        <component
          v-if="activeTab === tabIndex"
          :is="tab.component"
          :assignmentId="content.assignmentId"
          :invoiceId="content.id"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import InvoiceDetailsStaffCost from '@/components/Invoice/details/tabs/InvoiceDetailsStaffCost.vue';
import InvoiceDetailsTravelCost from '@/components/Invoice/details/tabs/InvoiceDetailsTravelCost.vue';
import InvoiceDetailsAdditionalCost from '@/components/Invoice/details/tabs/InvoiceDetailsAdditionalCost.vue';
import InvoiceDetailsAttachments from '@/components/Invoice/details/tabs/InvoiceDetailsAttachments';
import InvoiceDetailsPayment from '@/components/Invoice/details/tabs/InvoiceDetailsPayment';
import InvoiceDetailsHistory from '@/components/Invoice/details/tabs/InvoiceDetailsHistory';
import { mapActions } from 'vuex';

export default {
  components: {
    InvoiceDetailsStaffCost,
    InvoiceDetailsTravelCost,
    InvoiceDetailsAdditionalCost,
    InvoiceDetailsAttachments,
    InvoiceDetailsPayment,
    InvoiceDetailsHistory,
  },
  data() {
    return {
      tabs: [
        { name: 'Staff Costs', component: 'InvoiceDetailsStaffCost' },
        { name: 'Travel Costs', component: 'InvoiceDetailsTravelCost' },
        { name: 'Additional Costs', component: 'InvoiceDetailsAdditionalCost' },
        { name: 'Attachments', component: 'InvoiceDetailsAttachments' },
        { name: 'Payment', component: 'InvoiceDetailsPayment' },
        { name: 'History', component: 'InvoiceDetailsHistory' },
      ],
      activeTab: null,
      invoiceConfig: null,
    };
  },
  computed: {
    filteredTabs() {
      if (this.invoiceConfig && !this.invoiceConfig.tripEstimate?.showAdditionalInvoiceCost)
        return this.tabs.filter((tab) => tab.name !== 'Additional Charges');
      return this.tabs;
    },
  },
  async mounted() {
    this.invoiceConfig = await this.getConfig('tripRequestForm');
  },
  methods: {
    ...mapActions('config', ['getConfig']),
  },
  watch: {
    content: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.activeTab) {
          this.activeTab = newVal.activeTab;
        }
      },
    },
  },
  props: {
    content: { type: Object, default: () => ({}) },
  },
};
</script>

<style scoped>
.tt-tabs ::v-deep .v-tab--active {
  background-color: #e0e0e0; /* Change the background color of the active tab */
}

.tt-tabs ::v-deep .v-tabs-slider {
  background-color: #7ac755 !important; /* Change the color of the slider to green */
}
</style>
