<template>
  <v-container fluid>
    <div class="d-flex flex-row">
      <v-btn :loading="isDownloadOngoing" @click="processDownload" class="mb-2">
        <v-icon>mdi-download</v-icon>
        Download Selected
      </v-btn>
      <v-btn @click="clickUpload" class="ml-5 mb-2" :loading="isUploading" v-if="!invoiceReadOnly">
        <v-icon>mdi-plus</v-icon>
        ATTACHMENT
      </v-btn>
      <upload-file
        v-show="false"
        ref="uploadFile"
        recordType="invoice"
        :recordId="invoiceId"
        showSize
        persistentHint
        :hideInput="true"
        :readonly="false"
        @onUpload="onUpload"
      ></upload-file>
    </div>
    <Table
      :rows="invoiceDetailsAttachments"
      :headers="headers"
      :color="colors.lightRed"
      :menuOptions="menuOptions"
      show-select
      v-model="selected"
    >
    </Table>
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'Attachments'"
      :parentId="invoiceId"
      class="pt-4"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { inputProps, colors, humanizeFileSize, toDateString } from '@/util';
import { downloadAttachmentMixin } from '@/util/downloadAttachment';
import { Table } from '@/components/shared';
import UploadFile from '@/components/UploadFile.vue';
import { CustomFormFieldsDisplay } from '@/components/shared';
import ApiAttachment from '@/apis/attachment';

export default {
  props: {
    invoiceId: { type: Number, default: 0 },
  },
  components: {
    Table,
    UploadFile,
    CustomFormFieldsDisplay,
  },
  data() {
    return {
      colors,
      inputProps,
      selected: [],
      headers: [
        {
          text: 'File Name',
          value: 'filename',
        },
        {
          text: 'Date',
          value: 'created',
          render: (item) => toDateString(item.created),
        },
        {
          text: 'Format',
          value: 'mime',
        },
        {
          text: 'Size',
          value: 'size',
          render: (item) => {
            return humanizeFileSize(item.size);
          },
        },
        {
          text: 'Owner',
          value: 'createdby',
        },
      ],
      menuOptions: [
        {
          text: 'Download',
          click: (item) => {
            window.open(`/${this.client}/attachment/${item.id}/${encodeURIComponent(item.filename)}`, '_blank');
          },
          icon: 'mdi-download',
        },
        {
          text: 'Delete',
          click: async (item) => {
            const ok = await this.$myconfirm(`Are you sure you want to remove ${item?.filename}?`);
            if (!ok) return;
            await this.deleteFile({ id: item.id, filename: encodeURIComponent(item.filename) });
            await this.getAttachments(this.invoiceId);
          },
          disabled: () => this.invoiceReadOnly,
          icon: 'mdi-delete',
        },
      ],
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters('invoice', ['invoiceReadOnly', 'invoiceDetailsAttachments']),
    ...mapGetters('app', ['client']),
    selectedIds() {
      return this.selected.map((item) => item.id);
    },
  },
  methods: {
    ...mapActions('invoice', ['removeAttachment', 'getAttachments']),
    ...mapActions('attachment', ['deleteFile', 'downloadFiles']),

    clickUpload() {
      this.$refs.uploadFile.mimicClick();
    },
    onUpload(val) {
      if (val == 'uploading') {
        this.isUploading = true;
        return;
      }

      this.isUploading = false;

      if (typeof val === 'undefined') {
        this.getAttachments(this.invoiceId);
      }
    },

    async processDownload() {
      if (this.selectedIds.length <= 1) {
        this.$myalert.error('Please select at least 2 attachments to batch download.', true);
        return;
      }

      const callback = async () => {
        return ApiAttachment.downloadFiles(this.client, this.selectedIds);
      };

      this.downloadAttachment(callback, `invoice-${this.invoiceId}-attachments.zip`);
    },
  },
  mixins: [downloadAttachmentMixin],
};
</script>
