<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="text-capitalize" text v-on="on">
          Sort: <b>{{ sortDescription }}</b>
          <v-icon right>
            {{ sortIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(option, index) in invoiceSortOptions" :key="index" @click="sortInvoice(option)">
          <v-list-item-title>{{ option.text }}</v-list-item-title>
          <v-list-item-icon v-if="isCurrentSort(option)">
            <v-icon>
              {{ sortIcon }}
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('invoice', ['invoiceSortOptions', 'invoiceCurrentSort']),
    sortIcon() {
      return this.invoiceCurrentSort.order === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    sortDescription() {
      const sort = this.invoiceSortOptions.find((option) => option.value === this.invoiceCurrentSort.column);
      return sort.text;
    },
  },
  methods: {
    ...mapMutations('invoice', ['setInvoiceSort']),
    ...mapActions('invoice', ['getInvoices']),
    isCurrentSort(option) {
      return this.invoiceCurrentSort.column === option.value;
    },
    sortInvoice(option) {
      const isSameColumn = this.invoiceCurrentSort.column === option.value;

      const order = isSameColumn && this.invoiceCurrentSort.order === 'asc' ? 'desc' : 'asc';

      this.setInvoiceSort({
        column: option.value,
        order,
      });
      this.getInvoices();
    },
  },
};
</script>
