<template>
  <v-container fluid>
    <v-row class="pb-2" v-if="isLoading">
      <v-col cols="12">
        <v-progress-linear color="primary" absolute indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <section>
      <v-row>
        <v-col cols="4">
          <v-select
            v-bind="inputProps"
            :items="enumToArray(travelTypeMap)"
            v-model="travelType"
            label="Travel Input"
            :disabled="invoiceReadOnly || isLoading"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
            v-bind="{ dense: true, 'hide-details': true }"
            v-model="selectedRateTypes"
            v-for="item in rateTypes"
            :disabled="invoiceReadOnly || isLoading"
            :readonly="selectedRateTypes?.length === 1 && selectedRateTypes[0] === item.type"
            :label="item.text"
            :key="item.type"
            :value="item.type"
            @change="recalculateEntries"
          />
        </v-col>
        <v-col></v-col>
      </v-row>
    </section>

    <section class="py-3">
      <v-form ref="formTravelCost" v-model="travelCostForm.valid">
        <Table
          :menuOptions="menuOptions"
          :itemsPerPage="-1"
          :color="colors.lightBlue"
          sortable
          :headers="headers"
          :rows="rows"
          :show-disabled="false"
        >
          <template #footer>
            <tfoot>
              <tr v-if="travelCostForm.show">
                <th>
                  <DatePicker
                    v-if="travelCostForm.date !== null"
                    :rules="[
                      rules.required,
                      rules.date,
                      rules.minDate(invoiceDetailsTravelCost.leaveDate),
                      rules.maxDate(invoiceDetailsTravelCost.returnDate),
                      rules.allowedDates(travelCostDates),
                    ]"
                    label="Date"
                    v-bind="inputProps"
                    v-model="travelCostForm.date"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.pickUp !== null"
                    :rules="[rules.required, rules.decimal]"
                    label="Pick Up (mi)"
                    v-bind="inputProps"
                    v-model="travelCostForm.pickUp"
                    type="number"
                    :hint="pickUpMilesHint"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.startOdometer !== null"
                    :rules="[
                      rules.required,
                      rules.numeric,
                      rules.min(0),
                      rules.max(travelCostForm.endOdometer),
                      rules.decimal,
                    ]"
                    label="Start Odometer"
                    v-bind="inputProps"
                    v-model="travelCostForm.startOdometer"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.endOdometer !== null"
                    :rules="[rules.required, rules.numeric, rules.min(travelCostForm.startOdometer), rules.decimal]"
                    label="End Odometer"
                    v-bind="inputProps"
                    v-model="travelCostForm.endOdometer"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.milesPerGallon !== null"
                    :rules="[rules.required, rules.numeric, rules.min(1), rules.decimal]"
                    label="Miles Per Gallon"
                    v-bind="inputProps"
                    v-model="travelCostForm.milesPerGallon"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.fuelUsed !== null"
                    :rules="[rules.required, rules.numeric, rules.min(0.01), rules.decimal]"
                    label="Fuel (gal)"
                    v-bind="inputProps"
                    v-model="travelCostForm.fuelUsed"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.fuelRate !== null"
                    :rules="[rules.required, rules.numeric, rules.min(0), rules.decimal]"
                    label="Fuel Rate"
                    v-bind="inputProps"
                    v-model="travelCostForm.fuelRate"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.mileage !== null"
                    :rules="[rules.required, rules.decimal]"
                    label="Mileage (mi)"
                    v-bind="{
                      ...inputProps,
                      readonly: hasOdometerReading(travelCostForm),
                      disabled: hasOdometerReading(travelCostForm),
                    }"
                    v-model="travelCostForm.mileage"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.mileageRate !== null"
                    :rules="[rules.required, rules.decimal]"
                    label="Mileage Rate"
                    v-bind="inputProps"
                    v-model="travelCostForm.mileageRate"
                    type="number"
                  />
                </th>
                <th>
                  <v-text-field
                    v-if="travelCostForm.additionalMileageCost !== null"
                    :rules="[rules.required, rules.decimal]"
                    label="Additional Cost Per Mile"
                    v-bind="inputProps"
                    v-model="travelCostForm.additionalMileageCost"
                    readonly
                    type="number"
                  />
                </th>
                <th>{{ computedTravelCost() }}</th>
                <th></th>
              </tr>
              <tr v-if="travelCostForm.show">
                <th :colspan="headers.length + 1" class="text-center">
                  <v-btn
                    :loading="isLoading"
                    small
                    tile
                    depressed
                    color="success"
                    class="mr-2"
                    @click="
                      saveTravelCost({
                        isFormValid: travelCostForm.valid,
                        validate: () => validateForm($refs.formTravelCost),
                        success: initTravelCostForm,
                        form: travelCostForm,
                      })
                    "
                    :disabled="isLoading"
                    >Save</v-btn
                  >
                  <v-btn :loading="isLoading" small tile depressed color="error" @click="initTravelCostForm"
                    >Cancel</v-btn
                  >
                </th>
              </tr>
              <tr>
                <th>
                  <v-btn
                    text
                    @click="showTravelCostForm"
                    :disabled="isFetchingPickupMiles || travelCostForm.show"
                    v-if="!invoiceReadOnly"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    <span>New Line</span>
                  </v-btn>
                </th>
                <th :colspan="headers.length - 2"></th>
                <th>Total: {{ grandTotalTravelCost }}</th>
                <th></th>
              </tr>
            </tfoot>
          </template>
        </Table>
      </v-form>
    </section>
    <InvoiceDetailsEditTravelCost
      v-if="editDialog.visible"
      :details="editDialog.details"
      :visible.sync="editDialog.visible"
      :fields="headers"
      :rates="selectedRateTypes"
      :setFormMileage="setFormMileage"
      :validateForm="validateForm"
      :save="saveTravelCost"
      :hasOdometerReading="hasOdometerReading"
      :pickUpMilesHint="pickUpMilesHint"
    />
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'Travel Costs'"
      :parentId="invoiceId"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import { colors, inputProps, pluralize, toNumberString, toDateString } from '@/util';
import { computeTotal } from '@common/invoice/travel-cost-total';
import { mapActions, mapGetters } from 'vuex';
import { Table } from '@/components/shared';
import InvoiceDetailsEditTravelCost from '@/components/Invoice/details/InvoiceDetailsEditTravelCost';
import { required, numeric, max, min, date, minDate, maxDate, allowedDates, decimalPlaces } from '@/util/rules';
import { travelTypeMap, enumToArray } from '@/util/enums';
import { CustomFormFieldsDisplay } from '@/components/shared';

const defaults = {
  rateType: ['mileageRate'],
  travelType: 'estimated_mileage',
};

export default {
  props: {
    invoiceId: { type: Number, default: 0 },
  },
  components: { Table, InvoiceDetailsEditTravelCost, DatePicker, CustomFormFieldsDisplay },
  data() {
    return {
      defaultValues: null,
      pickUpError: null,
      travelTypeMap,
      colors,
      rules: {
        numeric,
        required,
        max,
        min,
        date,
        minDate,
        maxDate,
        allowedDates,
        decimal: decimalPlaces(2),
      },
      allowedHeadersMap: {
        estimated_mileage: {
          mileageRate: ['date', 'pickUp', 'mileageRate', 'mileage', 'additionalMileageCost', 'total'],
          fuelRate: ['date', 'pickUp', 'fuelUsed', 'fuelRate', 'milesPerGallon', 'total'],
        },
        actual_mileage: {
          mileageRate: ['date', 'pickUp', 'startOdometer', 'endOdometer', 'mileageRate', 'mileage', 'additionalMileageCost', 'total'],
          fuelRate: [
            'date',
            'pickUp',
            'startOdometer',
            'endOdometer',
            'fuelUsed',
            'fuelRate',
            'milesPerGallon',
            'total',
          ],
        },
      },
      editDialog: {
        visible: false,
        details: null,
      },
      rateTypes: [
        { type: 'mileageRate', text: 'Use Mileage Rate' },
        { type: 'fuelRate', text: 'Use Fuel Rate' },
      ],
      selectedRateTypes: [],
      travelTypeHeaders: [
        {
          text: 'Date',
          value: 'date',
          render: (item) => {
            return toDateString(new Date(item.date));
          },
          disabled: true,
        },
        {
          text: 'Pick Up (mi)',
          value: 'pickUp',
          disabled: true,
          render: (item) => `${toNumberString(item.pickUp)} mi`,
        },
        {
          text: 'Start Odometer',
          value: 'startOdometer',
          disabled: true,
          render: (item) => `${toNumberString(item.startOdometer)} mi`,
        },
        {
          text: 'End Odometer',
          value: 'endOdometer',
          disabled: true,
          render: (item) => `${toNumberString(item.endOdometer)} mi`,
        },
        {
          text: 'Miles Per Gallon',
          value: 'milesPerGallon',
          disabled: true,
          render: (item) => `${toNumberString(item.milesPerGallon)} gal`,
        },
        {
          text: 'Fuel (gal)',
          value: 'fuelUsed',
          disabled: true,
          render: (item) => `${toNumberString(item.fuelUsed)} gal`,
        },
        { text: 'Fuel Rate', value: 'fuelRate', disabled: true, render: (item) => `$${toNumberString(item.fuelRate)}` },
        {
          text: 'Mileage (mi)',
          value: 'mileage',
          disabled: true,
          render: (item) => `${toNumberString(item.mileage)} mi`,
        },
        {
          text: 'Mileage Rate',
          value: 'mileageRate',
          disabled: true,
          render: (item) => `$${toNumberString(item.mileageRate)}`,
        },
        {
          text: 'Additional Cost Per Mile',
          value: 'additionalMileageCost',
          disabled: true,
          render: (item) => `$${toNumberString(item.additionalMileageCost)}`,
        },
        {
          text: 'Costs',
          value: 'total',
          render: (item) => {
            const cost = computeTotal(item);
            return `$${toNumberString(cost)}`;
          },
          disabled: true,
        },
      ],
      travelType: defaults.travelType,
      inputProps: { ...inputProps, dense: true },
      travelCostForm: { valid: true },
      menuOptions: [
        {
          text: 'Edit',
          click: (item) => {
            this.setEditDialogVisibility({
              visible: true,
              details: {
                travelCost: item,
                invoiceId: this.invoiceId,
              },
            });
          },
          disabled: () => this.invoiceReadOnly,
          icon: 'mdi-pencil',
        },
        {
          text: 'Delete',
          click: (item) => {
            this.deleteInvoiceTravelById({
              id: item.id,
              invoiceId: this.invoiceId,
            });
          },
          disabled: () => this.invoiceReadOnly,
          icon: 'mdi-delete',
        },
      ],
      setupPickUpMiles: 0,
      isFetchingPickupMiles: false,
    };
  },
  computed: {
    ...mapGetters('invoice', [
      'invoiceReadOnly',
      'invoiceIsFetchingTravelCost',
      'invoiceDetailsTravelCost',
      'selectedInvoice',
    ]),
    ...mapGetters('config', ['invoiceConfig']),
    isLoading() {
      return this.invoiceIsFetchingTravelCost || this.isFetchingPickupMiles;
    },
    pickUpMilesHint() {
      if (this.pickUpError) return `Unable to determine pickup miles: ${this.pickUpError}`;

      const type = this.invoiceConfig.pickupMilesApplication;
      return `The pick up miles will ${type} value to the mileage.`;
    },
    travelCostDates() {
      if (!this.invoiceDetailsTravelCost?.invoiceTravel?.length) return [];
      return this.invoiceDetailsTravelCost?.invoiceTravel?.map((cost) => cost.date);
    },
    grandTotalTravelCost() {
      let total = 0;
      this.rows.forEach((item) => {
        total += computeTotal(item);
      });
      return `$${toNumberString(total)}`;
    },
    headers() {
      const displayHeaders = [];

      const travelTypeHeader = this.allowedHeadersMap[this.travelType];
      if (!travelTypeHeader) return [];

      this.selectedRateTypes.forEach((rateType) => {
        const allowedHeaders = travelTypeHeader[rateType];
        if (allowedHeaders) {
          displayHeaders.push(...allowedHeaders);
        }
      });

      const allowedHeaders = [...new Set(displayHeaders.flat())];

      return this.travelTypeHeaders.map((header) => {
        const isAllowed = allowedHeaders.includes(header.value);
        return {
          ...header,
          disabled: !isAllowed,
          hideText: !isAllowed,
        };
      });
    },
    rows() {
      if (!this.invoiceDetailsTravelCost) return [];

      return this.invoiceDetailsTravelCost.invoiceTravel;
    },
  },
  watch: {
    travelType() {
      this.initTravelCostForm();
    },
    selectedRateTypes(newVal, oldVal) {
      this.initTravelCostForm();
      if (!newVal.length) {
        this.$nextTick(() => {
          this.$myalert.error('At least one rate type must be selected.', true);
          this.selectedRateTypes = oldVal;
          this.setFormRateType(this.travelCostForm);
        });
      }
    },
    'travelCostForm.startOdometer'(newVal, oldVal) {
      this.handleOdometerChange(oldVal);
    },
    'travelCostForm.endOdometer'(newVal, oldVal) {
      this.handleOdometerChange(oldVal);
    },
  },
  async mounted() {
    try {
      this.isFetchingPickupMiles = true;
      this.initTravelCostForm();
      await this.fetchTravelCosts();

      const { pickUpMiles, error } = await this.getSetupTableTravelCost({ invoiceId: this.invoiceId, onlyPickUpMiles: true });
      this.setupPickUpMiles = parseFloat(pickUpMiles.toFixed(2));

      if (error) {
        this.pickUpError = error;
      }
    } catch (e) {
      this.$myalert.error('A problem occurred while fetching pick up miles distance.', true);
    } finally {
      this.isFetchingPickupMiles = false;
      this.setInputs();
    }
  },
  methods: {
    computeTotal,
    pluralize,
    enumToArray,
    ...mapActions('invoice', [
      'getInvoiceDetails',
      'getSetupTableTravelCost',
      'saveInvoiceTravelCost',
      'deleteInvoiceTravelById',
    ]),
    async getTravelCostDefaults() {
      if (this.defaultValues) {
        return this.defaultValues;
      }
      this.defaultValues = await this.getSetupTableTravelCost({ invoiceId: this.invoiceId });
      return this.defaultValues;
    },
    hasOdometerReading(form) {
      return !!parseFloat(form.endOdometer);
    },
    handleOdometerChange(oldVal) {
      this.$nextTick(() => {
        this.setFormMileage(this.travelCostForm);

        if ([undefined, null].includes(oldVal)) return; // Skips initial form validate when loading the form
        this.validateForm(this.$refs.formTravelCost);
      });
    },
    saveTravelCost({ validate, success, form }) {
      validate();
      if (!form.valid) return;
      this.setFormRateType(form);

      this.saveInvoiceTravelCost({
        body: [
          {
            id: form.id || null,
            date: form.date,
            pickUp: parseFloat(form.pickUp) || 0,
            startOdometer: parseFloat(form.startOdometer) || 0,
            endOdometer: parseFloat(form.endOdometer) || 0,
            milesPerGallon: parseFloat(form.milesPerGallon) || 0,
            fuelUsed: parseFloat(form.fuelUsed) || 0,
            fuelRate: parseFloat(form.fuelRate) || 0,
            mileage: parseFloat(form.mileage) || 0,
            mileageRate: parseFloat(form.mileageRate) || 0,
            additionalMileageCost: parseFloat(form.additionalMileageCost) || 0,
            travelType: this.travelType,
            isMileageRate: form.isMileageRate,
            isFuelRate: form.isFuelRate,
          },
        ],
        invoiceId: this.invoiceId,
      })
        .then(() => {
          this.$myalert.success('Travel cost saved successfully.', true);
          success();
        })
        .catch((error) => {
          this.$myalert.error(
            error.response?.data?.message || error.message || 'Failed to save travel cost. Please try again.',
            true
          );
        });
    },
    computedTravelCost() {
      const cost = computeTotal(this.travelCostForm);

      return `$${toNumberString(cost)}`;
    },
    async showTravelCostForm() {
      this.initTravelCostForm();
      const travelCostDefaults = await this.getTravelCostDefaults();
      if (this.travelType === 'estimated_mileage') travelCostDefaults.mileage = +this.selectedInvoice.distance;

      this.travelCostForm.show = true;
      this.headers.forEach((header) => {
        if (!header.disabled) this.travelCostForm[header.value] = travelCostDefaults[header.value];
      });

      this.travelCostForm.pickUp = this.setupPickUpMiles;
    },
    initTravelCostForm() {
      this.travelCostForm = {
        show: false,
        date: null,
        pickUp: null,
        startOdometer: null,
        endOdometer: null,
        mileage: null,
        mileageRate: null,
        milesPerGallon: null,
        additionalMileageCost: null,
        fuelUsed: null,
        fuelRate: null,
        isMileageRate: null,
        isFuelRate: null,
      };
      this.setFormRateType(this.travelCostForm);
    },
    async recalculateEntries() {
      const travelCostDefaults = await this.getTravelCostDefaults();
      const isMileageRate = this.selectedRateTypes.includes('mileageRate');
      const isFuelRate = this.selectedRateTypes.includes('fuelRate');
      const body = [];
      for (const invoiceTravel of this.invoiceDetailsTravelCost.invoiceTravel) {
        const updatedInvoiceTravel = {
          id: invoiceTravel.id,
          invoiceId: invoiceTravel.invoiceId,
          pickUp: parseFloat(invoiceTravel.pickUp) || 0,
          startOdometer: parseFloat(invoiceTravel.startOdometer) || 0,
          endOdometer: parseFloat(invoiceTravel.endOdometer) || 0,
          milesPerGallon: parseFloat(invoiceTravel.milesPerGallon) || 0,
          fuelUsed: parseFloat(invoiceTravel.fuelUsed) || 0,
          fuelRate: parseFloat(invoiceTravel.fuelRate) || 0,
          mileage: parseFloat(invoiceTravel.mileage) || 0,
          mileageRate: parseFloat(invoiceTravel.mileageRate) || 0,
          additionalMileageCost: parseFloat(invoiceTravel.additionalMileageCost) || 0,
          travelType: invoiceTravel.travelType,
          isMileageRate,
          isFuelRate,
        };
        // auto fill blank values with default values
        this.headers.forEach((header) => {
          if (!header.disabled && !Number(invoiceTravel[header.value]) && Number(travelCostDefaults[header.value])) {
            updatedInvoiceTravel[header.value] = travelCostDefaults[header.value];
          }
        });

        body.push(updatedInvoiceTravel);
      }

      await this.saveInvoiceTravelCost({
        body,
        invoiceId: this.invoiceId,
      });
    },
    async fetchTravelCosts() {
      await this.getInvoiceDetails({ tab: 'travel_cost', invoiceId: this.invoiceId });
    },
    setEditDialogVisibility({ visible, details }) {
      const clonedDetails = JSON.parse(JSON.stringify(details));

      if (clonedDetails.travelCost.date) clonedDetails.travelCost.date = clonedDetails.travelCost.date.split('T')[0];

      this.setDisabledValsToZero({ form: clonedDetails.travelCost, rates: this.selectedRateTypes });

      this.editDialog.visible = visible;
      this.editDialog.details = clonedDetails;
    },
    setDisabledValsToZero({ form, rates }) {
      if (rates.length === this.rateTypes.length) return;

      const allFields = this.headers.map((header) => header.value);

      let allowedFieldsSet = new Set();

      rates.forEach((rate) => {
        const allowedFields = this.allowedHeadersMap[this.travelType][rate];
        if (allowedFields) {
          allowedFields.forEach((field) => allowedFieldsSet.add(field));
        }
      });

      allFields.forEach((field) => {
        if (!allowedFieldsSet.has(field)) {
          form[field] = 0;
        }
      });
    },
    setFormMileage(form) {
      const start = parseFloat(form.startOdometer) || 0;
      const end = parseFloat(form.endOdometer) || 0;

      form.mileage = Number((end - start).toFixed(2));

      if (this.selectedRateTypes.includes('fuelRate') && form.mileage) {
        form.fuelUsed = Number((form.mileage / form.milesPerGallon).toFixed(2));
      }
    },
    setFormRateType(form) {
      form.isMileageRate = this.selectedRateTypes.includes('mileageRate');
      form.isFuelRate = this.selectedRateTypes.includes('fuelRate');
    },
    validateForm(ref) {
      if (!ref) return;
      this.$nextTick(() => {
        ref.validate();
      });
    },
    setInputs() {
      this.$nextTick(() => {
        if (!this.invoiceDetailsTravelCost || !this.invoiceDetailsTravelCost.invoiceTravel.length) {
          this.selectedRateTypes = defaults.rateType;
          this.travelType = defaults.travelType;
          return;
        }

        this.selectedRateTypes = [];
        const firstRow = this.invoiceDetailsTravelCost.invoiceTravel[0];
        if (firstRow.isMileageRate) this.selectedRateTypes.push('mileageRate');
        if (firstRow.isFuelRate) this.selectedRateTypes.push('fuelRate');
        this.travelType = firstRow.travelType;
      });
    },
  },
};
</script>
