<template>
  <div>
    <v-btn icon @click="openCalc">
      <v-icon>mdi-calculator</v-icon>
    </v-btn>
    <Dialog v-model="dialog">
      <template #header>Enter Breaks</template>
      <template #body>
        <v-row v-for="(breakEntry, index) in breaks" :key="index">
          <v-col>
            <TimePicker v-bind="inputProps" v-model="breakEntry.start" label="Start" />
          </v-col>
          <v-col>
            <TimePicker v-bind="inputProps" v-model="breakEntry.end" label="End" />
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="removeBreak(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="font-weight-bold text-h5 text-right">Total Breaks: {{ totalBreaks }} minutes</div>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-btn @click="addBreak" color="primary">
          <v-icon left>mdi-plus</v-icon>
          Add Break
        </v-btn>
        <v-btn :disabled="!breaks.length" @click="saveBreaks" color="success">
          <v-icon left>mdi-check</v-icon>
          OK
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/shared/Dialog';
import TimePicker from '@/components/TimePicker';
import { inputProps } from '@/util';

export default {
  components: { Dialog, TimePicker },
  data() {
    return {
      inputProps: { ...inputProps, dense: true },
      breaks: [],
      dialog: false,
    };
  },
  computed: {
    totalBreaks() {
      return this.breaks.reduce((total, breakEntry) => {
        if (breakEntry.start && breakEntry.end) {
          const startTime = this.convertTimeToMinutes(breakEntry.start);
          const endTime = this.convertTimeToMinutes(breakEntry.end);
          return total + Math.max(0, endTime - startTime);
        }
        return total;
      }, 0);
    },
  },
  methods: {
    openCalc() {
      this.dialog = true;
    },
    addBreak() {
      this.breaks.push({ start: '1:00', end: '1:30' });
    },
    removeBreak(index) {
      this.breaks.splice(index, 1);
    },
    saveBreaks() {
      if (this.totalBreaks > 0) this.$emit('input', this.totalBreaks);

      this.breaks = [];
      this.dialog = false;
    },
    convertTimeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    },
  },
};
</script>
