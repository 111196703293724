<template>
  <v-toolbar-items class="w-full">
    <div class="d-flex align-center w-full">
      <v-text-field
        v-if="onTextSearchDebounced"
        class="search-field"
        hide-details
        clearable
        prepend-icon="mdi-magnify"
        single-line
        @click:clear="handleClearFilter"
        @input="onTextSearchDebounced"
        :disabled="isInvoiceFilterDisabled"
        v-model="search"
      ></v-text-field>
      <div v-if="isOperationsEnabled">
        <sub-menu name="Operations" :menu-items="operationMenu" @sub-menu-click="operationSelected" />
      </div>
      <div>
        <sub-menu
          name="Filters"
          :disabled="isInvoiceFilterDisabled"
          :menu-items="filterMenuItems"
          @sub-menu-click="onMenuItemClick"
        />
      </div>
      <div>
        <sub-menu
          name="Visibility"
          :disabled="isInvoiceFilterDisabled"
          :menu-items="visibleColumnsSetup"
          @sub-menu-click="setColumnVisibility"
        />
      </div>
      <InvoiceSort />
      <v-spacer></v-spacer>
      <div>
        <InvoiceActiveFilters />
        <InvoiceColumnVisibility />
        <v-btn :disabled="isInvoiceFilterDisabled" text color="black" @click="getInvoices" class="text-capitalize">
          <v-icon left>mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </div>
    </div>

    <TTDialog v-model="showDateRangeFilter">
      <template #header>Select a Date Range</template>
      <template #body>
        <v-row>
          <v-col cols="12" sm="6">
            <DatePicker label="Start Date" v-model="startDate" />
          </v-col>
          <v-col cols="12" sm="6">
            <DatePicker label="End Date" v-model="endDate" />
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-btn
          :disabled="isOkButtonDisabled"
          color="primary"
          @click="setDateFilter({ type: 'range', startDate, endDate })"
          >OK</v-btn
        >
      </template>
    </TTDialog>
    <TTDialog v-model="showDateYearMonthFilter" width="auto">
      <template #header>Select a Date Month</template>
      <template #body>
        <v-date-picker type="month" v-model="startDate"></v-date-picker>
      </template>
      <template #actions>
        <v-btn :disabled="isOkButtonDisabled" color="primary" @click="setDateFilter({ type: 'year_month', startDate })"
          >OK</v-btn
        >
      </template>
    </TTDialog>
    <id-picker ref="idPicker" title="Trip #" filterType="invoice" :filter="{ name: 'Trip #', field: 'id' }"></id-picker>
    <InvoiceOperationModal
      v-model="isShowOperationDialog"
      :selectedOperation="selectedOperation"
      @updated="handleSuccessfulUpdate"
    ></InvoiceOperationModal>
  </v-toolbar-items>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import SubMenu from '@/components/SubMenu.vue';
import TTDialog from '@/components/shared/Dialog.vue';
import InvoiceOperationModal from '@/components/Invoice/menu/operations/OperationModal.vue';
import InvoiceActiveFilters from '@/components/Invoice/menu/InvoiceActiveFilters.vue';
import InvoiceSort from '@/components/Invoice/menu/InvoiceSort.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uniqBy, cloneDeep } from 'lodash';
import { dateWithOffset } from '@/util';
import debounceMixin from '@/mixins/debounceMixin';
import IdPicker from '@/components/IdPicker.vue';
import { format, lastDayOfMonth } from 'date-fns';
import InvoiceColumnVisibility from './InvoiceColumnVisibility.vue';

export default {
  name: 'InvoiceListMenu',
  inject: ['eventHub'],
  mixins: [debounceMixin],
  components: {
    IdPicker,
    SubMenu,
    TTDialog,
    DatePicker,
    InvoiceActiveFilters,
    InvoiceSort,
    InvoiceColumnVisibility,
    InvoiceOperationModal,
  },
  data() {
    return {
      search: '',
      showDateYearMonthFilter: false,
      showDateRangeFilter: false,
      startDate: null,
      endDate: null,
      activeFilters: [],
      onTextSearchDebounced: null,
      operationMenu: [
        { name: 'Mass Approve', value: 'mass-approve', label: 'Approved', yields: 'approved', showTotals: false },
        { name: 'Mass Send', value: 'mass-send', label: 'Sent', yields: 'sent', showTotals: true },
        { name: 'Mass Pay', value: 'mass-pay', label: 'Paid', yields: 'paid', showTotals: true },
        {
          name: 'Mass Do not Bill',
          value: 'mass-do-not-bill',
          label: 'Do not Bill',
          yields: 'do_not_bill',
          showTotals: false,
        },
      ],
      selectedOperation: { name: null, label: null, value: null, yields: null },
      isShowOperationDialog: false,
    };
  },
  created() {
    this.onTextSearchDebounced = this.debounce(() => {
      this.setFilter('text', this.search);
    }, 500);
    this.eventHub.$on('addFilterInvoice', (item) => {
      if (item.search.value) {
        this.$nextTick(() => this.setFilter('tripRequestId', item.search.value));
      }
    });

    this.setOperationsMenuAccess();
  },
  beforeDestroy() {
    this.eventHub.$off('addFilterInvoice');
  },
  mounted() {
    this.setUserPreference(this.me.id);
    this.getVehicleOwners();
  },
  computed: {
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsById']),
    ...mapGetters('location', ['locations', 'locationsById', 'zones']),
    ...mapGetters('destination', ['destinations']),
    ...mapGetters('fundingSource', ['fundingSources']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    ...mapGetters('driver', ['drivers']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('user', ['users', 'me', 'usersByEmail', 'uniqueVehicleOwners']),
    ...mapGetters('invoice', ['invoiceFilters', 'statusOptions', 'isInvoiceFilterDisabled', 'invoiceColumns']),
    ...mapGetters('config', ['invoiceConfig']),
    filterMenuItems() {
      return [
        {
          name: 'Date',
          menu: [
            {
              name: 'Trips From This Day Forward',
              onClick: () => {
                this.setDateFilter({ type: 'today_onward' });
              },
            },
            {
              name: 'Today',
              onClick: () => {
                this.setDateFilter({ type: 'today' });
              },
            },
            {
              name: 'Year Month',
              onClick: () => {
                this.showDateYearMonthFilter = true;
              },
            },
            {
              name: 'Date Range',
              onClick: () => {
                this.showDateRangeFilter = true;
              },
            },
          ],
        },
        {
          name: 'Status',
          menu:
            this.statusOptions.length > 0
              ? this.statusOptions.map((e) => ({
                  name: e.text,
                  onClick: () => {
                    this.setStatusFilter({ status: e.value });
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'General',
          menu: [
            {
              name: 'Trip #',
              onClick: () => {
                this.$refs.idPicker.dialog = true;
              },
            },
            {
              name: 'Needs Driver',
              onClick: () => {
                this.setFilter('needsDriver', 1);
              },
            },
            {
              name: 'Needs Vehicle',
              onClick: () => {
                this.setFilter('needsVehicle', 1);
              },
            },
            {
              name: 'Mileage Entered',
              onClick: () => {
                this.setFilter('mileageEntered', 1);
              },
            },
            {
              name: 'Driver Hours Entered',
              onClick: () => {
                this.setFilter('driverHoursEntered', 1);
              },
            },
          ],
        },
        {
          name: 'Driver',
          menu:
            this.drivers.length > 0
              ? this.drivers.map((e) => ({
                  name: `${e.firstName} ${e.lastName}`,
                  onClick: () => {
                    this.setFilter('driverId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Driver Designation',
          menu: this.designationOptions,
        },
        {
          name: 'Budget Code',
          menu: this.budgetCodeOptions,
        },
        {
          name: 'Funding Source',
          menu:
            this.fundingSources.length > 0
              ? this.fundingSources.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.setFilter('fundingSourceId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Vehicle',
          menu:
            this.vehicles.length > 0
              ? this.vehicles.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.setFilter('vehicleId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Location',
          menu:
            this.locations.length > 0
              ? this.locations.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.addFilter('locationId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Destination',
          menu:
            this.destinations.length > 0
              ? this.destinations.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.setFilter('destinationId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Trip Batch #',
          menu: this.batchNumberOptions,
        },
        {
          name: 'Trip Event',
          menu:
            this.tripEvents.length > 0
              ? this.tripEvents.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.addFilter('tripEventId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Trip Type',
          menu:
            this.tripTypes.length > 0
              ? this.tripTypes.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.addFilter('tripTypeId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Vehicle Type',
          menu:
            this.vehicleTypes.length > 0
              ? this.vehicleTypes.map((e) => ({
                  name: e.name,
                  onClick: () => {
                    this.addFilter('vehicleTypeId', e.id);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Vehicle Owner',
          menu:
            this.uniqueVehicleOwners.length > 0
              ? this.uniqueVehicleOwners.map((e) => ({
                  name: e.displayName,
                  onClick: () => {
                    this.setFilter('vehicleOwner', e.userId);
                  },
                }))
              : [
                  {
                    name: 'No data available',
                    disabled: true,
                  },
                ],
        },
        {
          name: 'Zone',
          menu: this.zones.map((e) => ({
            name: e.text || e,
            disabled: e.disabled,
            onClick: () => {
              this.setFilter('zone', e);
            },
          })),
        },
      ];
    },
    budgetCodeOptions() {
      const budgetCodes = this.fundingSources
        .filter((fs) => !!fs.budgetCode)
        .map((e) => ({
          name: e.budgetCode,
          onClick: () => {
            this.setFilter('budgetCode', e.budgetCode);
          },
        }));
      return budgetCodes.length > 0
        ? budgetCodes
        : [
            {
              name: 'No data available',
              disabled: true,
            },
          ];
    },
    batchNumberOptions() {
      const batchNumbers = uniqBy(
        this.assignments
          .filter((e) => e.batchId)
          .map((e) => ({
            name: `# ${e.batchId}`,
            onClick: () => {
              this.setFilter('batchId', e.batchId);
            },
          })),
        'name'
      );
      return batchNumbers.length
        ? batchNumbers
        : [
            {
              name: 'No data available',
              disabled: true,
            },
          ];
    },
    designationOptions() {
      const uniqDesignations = new Set(this.drivers.filter((e) => !!e.designation).map((e) => e.designation));
      const designations = [...uniqDesignations].map((e) => ({
        name: e,
        onClick: () => {
          this.setFilter('driverDesignation', e);
        },
      }));
      return designations.length > 0
        ? designations
        : [
            {
              name: 'No data available',
              disabled: true,
            },
          ];
    },
    visibleColumnsSetup() {
      return this.invoiceColumns
        .filter((column) => !column.default)
        .map((column) => ({
          name: column.text,
          value: column.value,
        }));
    },
    isOkButtonDisabled() {
      if (this.showDateRangeFilter) {
        if (!this.startDate || !this.endDate) return true;
        if (new Date(this.endDate) < new Date(this.startDate)) return true;
      }
      if (this.showDateYearMonthFilter && !this.startDate) return true;

      return false;
    },
    isOperationsEnabled() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.schoolFinance ||
        this.me.is.fundingManager ||
        this.me.is.finance
      );
    },
  },
  methods: {
    ...mapMutations('invoice', ['setInvoiceFilters', 'setColumns', 'setUserPreference']),
    ...mapActions('invoice', ['getInvoices']),
    ...mapActions('user', ['getVehicleOwners']),
    onMenuItemClick(item) {
      if (item.onClick) item.onClick();
    },

    setColumnVisibility(item) {
      const columns = cloneDeep(this.invoiceColumns);

      for (let column of columns) {
        if (column.text === item.name) {
          column.show = !column.show;
          break;
        }
      }

      this.setColumns({ columns, userId: this.me.id });
    },
    setDateFilter({ type, startDate, endDate }) {
      const filters = { ...this.invoiceFilters };

      // cleanup
      delete filters.leaveDateOnward;
      delete filters.leaveDate;

      switch (type) {
        case 'range':
          filters.leaveDate = [startDate, endDate];
          break;
        case 'today':
          {
            const offsetDate = dateWithOffset(new Date());
            filters.leaveDate = format(offsetDate, 'yyyy-MM-dd');
          }
          break;
        case 'today_onward':
          {
            const offsetDate = dateWithOffset(new Date());
            filters.leaveDateOnward = format(offsetDate, 'yyyy-MM-dd');
          }
          break;
        case 'year_month':
          {
            const [year, month] = startDate.split('-');
            const startOfMonth = dateWithOffset(new Date(startDate + 'T00:00:00.000Z'));
            const endOfMonth = lastDayOfMonth(startOfMonth);
            filters.leaveDate = [`${startDate}-01`, `${startDate}-${endOfMonth.getDate()}`];
          }
          break;
      }
      this.setInvoiceFilters(filters);
      this.applyFilters();
      this.closeDialogs();
    },
    async setStatusFilter({ status }) {
      this.setFilter('status', status);
    },
    setFilter(key, value) {
      this.setInvoiceFilters({
        ...this.invoiceFilters,
        [key]: value,
      });
      this.applyFilters();
    },
    addFilter(key, value) {
      const existingFilter = this.invoiceFilters[key] ? [...this.invoiceFilters[key]] : [];
      existingFilter.push(value);
      this.setInvoiceFilters({
        ...this.invoiceFilters,
        [key]: [...(this.invoiceFilters[key] ? this.invoiceFilters[key] : []), value],
      });
      this.applyFilters();
    },
    closeDialogs() {
      this.showDateYearMonthFilter = false;
      this.showDateRangeFilter = false;
    },
    handleClearFilter() {
      this.search = '';
      this.setInvoiceFilters({});
    },
    applyFilters() {
      this.getInvoices();
    },
    operationSelected(item) {
      this.selectedOperation = item;
      this.isShowOperationDialog = true;
    },
    handleSuccessfulUpdate() {
      this.isShowOperationDialog = false;
      this.selectedOperation = { name: null, label: null, value: null, yields: null };
      this.getInvoices();
    },

    setOperationsMenuAccess() {
      if (this.me.is.fundingManager || this.me.is.schoolFinance) {
        this.operationMenu[0].disabled = true;
        this.operationMenu[2].disabled = true;
        this.operationMenu[3].disabled = true;
      }
    },
  },
};
</script>
<style>
.w-full {
  width: 100%;
}
</style>
