<template>
  <TTDialog v-model="dialog">
    <template #header>{{ selectedOperation?.name }}</template>
    <template #body>
      <v-row v-if="selectedOperation.showTotals">
        <v-col cols="6" class="d-flex">
          <span class="font-weight-bold">Total to be marked "{{ selectedOperation?.label }}":</span>
          <v-text-field
            v-model="selectedInvoicesTotal"
            readonly
            style="margin-top: -20px; margin-left: 15px"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col>
      </v-row>
      <div>
        <v-data-table
          :headers="headers"
          :items="operationData.invoices"
          :items-per-page="pageSetup.pageSize"
          :footer-props="{
            'items-per-page-options': [8],
          }"
          show-select
          v-model="operationData.selected"
          @item-selected="setSelection"
          class="elevation-1"
          @toggle-select-all="selectionChangedViaCheckbox"
          :loading="isTableLoading"
        >
          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox v-if="!isSingleSelect" v-bind="props" v-on="on"></v-simple-checkbox>
          </template>
          <template v-slot:item.invoicePayments="{ header, item }">
            {{ header.render(item) }}
          </template>
          <template v-slot:item.status="{ header, item }">
            <v-chip :color="getStatus(header, item).color" small class="font-weight-bold" label>
              {{ getStatus(header, item).text }}
            </v-chip>
          </template>
        </v-data-table>
      </div>
      <div class="d-flex justify-end">
        <v-pagination
          @input="pageChanged"
          v-model="pageSetup.currentPage"
          :length="Math.ceil(Number(pageSetup.length) / Number(pageSetup.pageSize))"
        ></v-pagination>
      </div>
    </template>
    <template #actions>
      <v-btn :loading="isProcessing" color="primary" @click="process()">{{ selectedOperation?.name }} Selected</v-btn>
    </template>
  </TTDialog>
</template>

<script>
import TTDialog from '@/components/shared/Dialog.vue';
import invoiceApi from '@/apis/invoice';
import { mapGetters } from 'vuex';
import { ROLES } from '@/shared/common';

export default {
  name: 'InvoiceOperationModal',
  components: {
    TTDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    selectedOperation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      operationData: {
        isAllSelected: false,
        invoices: [],
        selected: [],
        deselected: [],
        invoicesTotal: 0,
      },
      pageSetup: {
        currentPage: 1,
        pageSize: 8,
        length: 0,
      },
      isTableLoading: false,
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['invoiceConfig']),
    ...mapGetters('invoice', ['invoiceColumns', 'statusConfig', 'invoiceFilters']),
    headers() {
      return this.invoiceColumns.reduce((acc, header) => {
        if (!header.show) return acc;

        acc.push(header);
        return acc;
      }, []);
    },
    isSingleSelect() {
      if (this.me.is.superAdmin || this.me.is.finance) return false;

      for (let role of Object.keys(this.me.is))
        if (this.me.is[role] && this.invoiceConfig.allowInvoiceStatusBatchUpdate[role]) return false;
      return true;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    selectedInvoicesTotal() {
      let retVal = 0;
      if (this.operationData.isAllSelected)
        retVal =
          this.operationData.invoicesTotal -
          this.operationData.deselected.reduce((acc, deselected) => {
            return acc + Number(deselected.total);
          }, 0);
      else retVal = this.operationData.selected.reduce((acc, selected) => acc + Number(selected.total), 0);

      return retVal.toFixed(2);
    },

    isFundingManagerOrSchoolFinance() {
      return this.me.is.fundingManager || this.me.is.schoolFinance;
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.loadInvoices();
      } else {
        this.operationData = {
          isAllSelected: false,
          invoices: [],
          selected: [],
          deselected: [],
        };
        this.pageSetup.currentPage = 1;
      }
    },
  },

  methods: {
    loadInvoices() {
      this.isTableLoading = true;
      const notInStatus = [this.selectedOperation.yields];
      if (this.isFundingManagerOrSchoolFinance) this.invoiceFilters.status = 'approved';
      invoiceApi
        .getInvoices({
          options: {
            page: {
              currentPage: this.pageSetup.currentPage,
              pageSize: this.pageSetup.pageSize,
            },
            sort: {
              column: 'tripRequestId',
              order: 'desc',
            },
            filter: {
              notInStatus,
              ...this.invoiceFilters,
            },
            getTotalTranscendsPagination: this.selectedOperation.showTotals,
          },
        })
        .then((response) => {
          this.pageSetup.length = response.total;
          this.operationData.invoices = response.data;
          this.operationData.invoicesTotal = response.invoicesTotal;
          this.keepTrackOfSelection();
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    setSelection(item) {
      if (!item.value && this.operationData.isAllSelected) this.operationData.deselected.push(item.item);
      if (item.value && this.operationData.isAllSelected) {
        this.operationData.deselected = this.operationData.deselected.filter(
          (deselected) => deselected.id !== item.item.id
        );
      }
    },

    pageChanged(page) {
      this.pageSetup.currentPage = page;
      this.loadInvoices();
    },

    keepTrackOfSelection() {
      if (this.operationData.isAllSelected) {
        this.operationData.selected = this.operationData.invoices.filter(
          (invoice) => !this.operationData.deselected.find((deselected) => deselected.id === invoice.id)
        );
      }
    },

    selectionChanged(value) {
      this.operationData.deselected = [];
      if (value) {
        this.operationData.selected = [...this.operationData.invoices];
      } else {
        this.operationData.selected = [];
      }
    },

    selectionChangedViaCheckbox(value) {
      this.operationData.isAllSelected = value.value;
      this.selectionChanged(value.value);
    },

    process() {
      const ids = this.operationData.isAllSelected
        ? this.operationData.deselected.map((invoice) => invoice.id)
        : this.operationData.selected.map((invoice) => invoice.id);
      this.isProcessing = true;

      if (this.isFundingManagerOrSchoolFinance) this.invoiceFilters.status = 'approved';

      invoiceApi
        .batchUpdateInvoice({
          ids,
          isAllSelected: this.operationData.isAllSelected,
          status: this.selectedOperation.yields,
          filters: this.invoiceFilters,
        })
        .then(() => {
          this.$myalert.success('Operation completed successfully');
          this.$emit('updated', true);
        })
        .catch((error) => {
          this.$myalert.error('An error occurred while processing the operation: ', error?.message);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getStatus(header, item) {
      return header.render(item);
    },
  },
};
</script>
