export const staffCostMap = {
  status: {
    approved: 'Approved',
    do_not_bill: 'Do Not Bill',
    paid: 'Paid',
    pending: 'Pending',
    sent: 'Sent',
  },
  staffType: {
    driver: 'Driver',
    assistant: 'Assistant',
  },
  rateType: {
    regular: 'Regular',
    ot: 'OT',
    other: 'Other',
  },
};

export const travelTypeMap = {
  estimated_mileage: 'Estimated Mileage',
  actual_mileage: 'Actual Mileage',
  // Hiding these first until we use them
  // gps: 'GPS',
  // synovia: 'Synovia',
};

export const fundingSourceMap = {
  type: {
    singleBasedOnLoc: { name: "Single Budget Code Based on Requester's Loc/Dept", id: 1 },
    singleAllotment: { name: 'Single Budget Code - Allotments Allowed', id: 2 },
    multiple: { name: "Multiple Budget Codes Based on Requester's Loc/Dept", id: 3 },
    editable: { name: 'Editable/Blank Budget Code on Trip Request', id: 4 },
  },
};

export const enumToArray = (enumObject) => {
  if (!enumObject || typeof enumObject !== 'object') return [];
  return Object.entries(enumObject).map(([value, text]) => ({
    text,
    value,
  }));
};
