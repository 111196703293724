export default {
  methods: {
    debounce: function (fn, delay = 300) {
      let _debounceInterval = null;

      return function () {
        clearTimeout(_debounceInterval);

        const args = arguments;
        const that = this;

        _debounceInterval = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },
  },
};
