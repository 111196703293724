<template>
  <v-container fluid>
    <Table
      :headers="headers"
      :rows="rows"
      :itemsPerPage="-1"
      :color="colors.lightPink"
      sortable
      :loading="invoiceIsFetchingHistory"
      :menuOptions="menuOptions"
    >
      <template #top>
        <v-btn @click="showCommentForm" v-if="!invoiceReadOnly">
          <v-icon left>mdi-plus</v-icon>
          Add Comment
        </v-btn>
      </template>
    </Table>
    <InvoiceDetailsCommentDialog v-if="formDialog.visible" :visible.sync="formDialog.visible" :invoiceId="invoiceId" />
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'History'"
      :parentId="invoiceId"
      class="pt-4"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>
<script>
import { colors, inputProps, toDateString } from '@/util';
import InvoiceDetailsCommentDialog from '@/components/Invoice/details/InvoiceDetailsCommentDialog';
import { mapActions, mapGetters } from 'vuex';
import { Table } from '@/components/shared';
import { CustomFormFieldsDisplay } from '@/components/shared';
export default {
  props: {
    invoiceId: { type: Number, default: 0 },
  },
  components: { Table, InvoiceDetailsCommentDialog, CustomFormFieldsDisplay },
  data() {
    return {
      inputProps,
      colors,
      formDialog: {
        visible: false,
        details: null,
      },
      headers: [
        {
          text: 'Date',
          value: 'timestamp',
          render: (item) => toDateString(item.date),
        },
        {
          text: 'Description',
          value: 'description',
          classes: (item) => {
            const isBold = item.source === 'audit';
            const isItalic = item.source !== 'audit';
            return {
              'font-italic': isItalic,
              'font-weight-bold': isBold,
              'text-break': true,
            };
          },
        },
        { text: 'User', value: 'username' },
        { text: 'Device', value: 'device' },
        { text: 'Browser', value: 'browser' },
      ],
      menuOptions: [
        {
          text: 'Delete',
          click: (item) => this.deleteComment(item),
          icon: 'mdi-delete',
          disabled: (item) => {
            return this.invoiceReadOnly || item.source === 'audit';
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('invoice', ['invoiceReadOnly', 'invoiceDetailsHistory', 'invoiceIsFetchingHistory']),
    rows() {
      if (this.invoiceDetailsHistory) {
        return this.invoiceDetailsHistory;
      }
      return [];
    },
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceDetails', 'deleteInvoiceCommentById']),
    deleteComment(comment) {
      this.deleteInvoiceCommentById({ invoiceId: this.invoiceId, commentId: comment.id });
    },
    showCommentForm() {
      this.formDialog.visible = true;
    },
  },
  mounted() {
    this.getInvoiceDetails({ tab: 'history', invoiceId: this.invoiceId });
  },
};
</script>
