<template>
  <v-container fluid class="pt-0 px-1">
    <InvoiceDetailsHeader :content="content" class="mb-4" />
    <InvoiceDetailsTabs :content="content"/>
  </v-container>
</template>
<script>
import InvoiceDetailsHeader from '@/components/Invoice/details/InvoiceDetailsHeader.vue';
import InvoiceDetailsTabs from '@/components/Invoice/details/InvoiceDetailsTabs.vue';
export default {
  components: { InvoiceDetailsHeader, InvoiceDetailsTabs },
  props: {
    content: { type: Object, default: () => ({}) },
  },
};
</script>
