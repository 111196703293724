<template>
  <div>
    <Dialog :value="visible" @input="handleClose">
      <template #header>Add Comment</template>
      <template #body>
        <v-form v-model="form.valid">
          <v-textarea
            :maxlength="300"
            counter
            :rules="[rules.required]"
            label="Comment"
            v-model="form.comment"
            v-bind="inputProps"
          />
        </v-form>
      </template>
      <template #actions>
        <v-btn
          :disabled="!form.valid || loading"
          :loading="invoiceIsFetchingHistory"
          color="success"
          @click="addComment"
        >
          <v-icon left>mdi-content-save</v-icon>
          Save
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { Dialog } from '@/components/shared';
import { inputProps, toUcWords } from '@/util';
import { required } from '@/util/rules';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    visible: { type: Boolean, default: false },
    invoiceId: { type: Number, default: null },
  },
  components: { Dialog },
  computed: {
    ...mapGetters('invoice', ['invoiceIsFetchingHistory']),
    ...mapGetters('user', ['me']),
  },
  data() {
    return {
      inputProps,
      form: { comment: null, valid: false },
      loading: false,
      rules: { required },
      maxChars: 300,
    };
  },
  methods: {
    ...mapActions('invoice', ['addInvoiceComment']),
    async addComment() {
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        const form = {
          ...this.form,
        };
        delete form.valid;
        await this.addInvoiceComment({ invoiceId: this.invoiceId, commentForm: form });
        this.$myalert.success('Comment saved successfully.');
        this.handleClose();
      } catch (e) {
        this.$myalert.success('Error while saving the comment.');
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
