<template>
  <v-dialog v-model="visible" width="600px" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-title>Select a Funding Source</v-card-title>
      <v-form :value="$v.form.$invalid">
        <v-card-text>
          <v-row>
            <v-col cols="7">
              <v-select
                :items="filteredFundingSources"
                item-text="name"
                item-value="id"
                label="Funding Source"
                :rules="handleErrors($v.form.fundingSource)"
                v-model.trim="$v.form.fundingSource.$model"
                v-bind="inputProps"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <template v-if="selectedFundingSource">
                <BudgetCodeWithLocation
                  :locationId="currentTripRequest.locationId"
                  :fundingSource="selectedFundingSource"
                  :invoicePayment="data"
                  v-if="selectedFundingSource.type !== 4"
                  v-model="budgetCode"
                />
                <v-text-field
                  v-else
                  placeholder="Budget Code"
                  :readonly="!selectedFundingSource.codeIsEditable"
                  v-model="editedBudgetCode"
                  v-bind="inputProps"
                ></v-text-field>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <v-select
                clear-icon
                :items="fundingSourceTypes"
                item-value="value"
                placeholder="Funding Source Type"
                v-model="$v.form.fundingSourceType.$model"
                v-bind="inputProps"
              ></v-select>
            </v-col>
            <template v-if="$v.form.fundingSourceType.$model === 'percentage'">
              <v-col cols="5">
                <v-text-field
                  max="100"
                  placeholder="0"
                  suffix="%"
                  type="number"
                  v-model="$v.form.amount.$model"
                  v-bind="inputProps"
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="5">
                <v-text-field
                  placeholder="0.00"
                  prefix="$"
                  type="number"
                  v-model="$v.form.amount.$model"
                  v-bind="inputProps"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="select" :disabled="!isFormValid">Select</v-btn>
          <v-btn color="default" @click="handleClose()">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors, inputProps, getBudgetCodeDescriptionWithLocation } from '@/util';
import BudgetCodeWithLocation from '@/components/BudgetCode/BudgetCodeWithLocation.vue';
import { fundingSourceTypesMap } from '@/util/constants';

export default {
  mixins: [validationMixin],
  components: { BudgetCodeWithLocation },
  props: {
    visible: { type: Boolean, default: false },
    invoiceId: { type: Number, default: null },
    data: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('invoice', [
      'invoiceDetailsPayment',
      'invoiceIsFetchingPayment',
      'selectedInvoice',
      'invoiceTotalCosts',
    ]),
    ...mapGetters('fundingSource', ['fundingSources', 'filterFundingSources', 'fundingSourcesById']),
    ...mapGetters('user', ['me']),
    ...mapGetters('invoicePayment', ['fundingSourceTypes']),
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('tripRequest', ['currentTripRequest', 'isCurrentTripFundingNoBudgetCode']),
    isFormValid() {
      if (!this.$v.form.fundingSource.$model) {
        return !this.$v.form.$invalid;
      }
      return !this.$v.form.$invalid && this.selectedBudgetCode.description;
    },
    selectedFundingSource() {
      return this.fundingSourcesById?.[this.$v.form.fundingSource.$model] || null;
    },
    filteredFundingSources() {
      const invoicePayments = this.invoiceDetailsPayment.invoicePayments || [];
      const currentFundingSourceId = this.data.fundingSourceId;

      const existingFundingSourceIds = new Set(invoicePayments.map((ip) => ip.fundingSourceId));

      if (currentFundingSourceId) {
        existingFundingSourceIds.delete(currentFundingSourceId);
      }

      const fundingSources = this.filterFundingSources({
        tripTypeId: this.invoiceDetailsPayment.assignment.tripRequest.tripTypeId,
        locationId: this.invoiceDetailsPayment.assignment.tripRequest.locationId,
        existingFundingSourceIds,
        selected: null,
      });

      if (!invoicePayments.length) {
        fundingSources.push({ name: 'None', id: 0 });
      }

      return fundingSources;
    },
    selectedBudgetCode() {
      if (this.budgetCode) return { description: this.budgetCode.text, id: this.budgetCode.value };

      const fundingSource = this.selectedFundingSource;
      if (!fundingSource) return '';

      const location = this.locationsById[this.selectedInvoice.locationId];
      if (!location) return fundingSource?.budgetCodeDescription || fundingSource?.budgetCode;

      const description = getBudgetCodeDescriptionWithLocation({
        budgetCodeConfig: this.budgetCodeConfig,
        budgetCodes: this.budgetCodes,
        fundingSource,
        locationCode: location?.code,
      });

      return { description, id: fundingSource.budgetCodeId };
    },
  },
  data() {
    return {
      form: {
        fundingSource: null,
        fundingSourceType: '',
        amount: 0,
        checkNumber: null,
        batchJournalNumber: null,
      },
      handleErrors,
      inputProps,
      required,
      budgetCodes: [],
      budgetCode: null,
      editedBudgetCode: null,
      fundingSourceTypesMap,
    };
  },
  validations() {
    return {
      form: {
        fundingSource: { required },
        fundingSourceType: {},
        amount: {},
      },
    };
  },
  async created() {
    await this.getFundingSources();
  },
  methods: {
    ...mapActions('fundingSource', ['getFundingSources', 'saveFundingSource']),
    ...mapActions('invoice', ['saveInvoicePayment']),
    ...mapActions('budgetCode', ['getBudgetCodes']),
    async select() {
      if (this.$v.form.$invalid) return;

      try {
        let budgetCode = null;

        if (this.selectedFundingSource.type === this.fundingSourceTypesMap.editableBlank) {
          budgetCode = this.editedBudgetCode;
          await this.saveFundingSource({
            ...this.selectedFundingSource,
            budgetCode: this.editedBudgetCode,
          });
        }

        await this.saveInvoicePayment({
          invoiceId: this.invoiceId,
          body: {
            id: this.form?.id || null,
            fundingSourceId: this.$v.form.fundingSource.$model,
            budgetCode: budgetCode || this.selectedBudgetCode.description,
            budgetCodeId: this.selectedBudgetCode.id,
            fundingSourceType: this.$v.form.fundingSourceType.$model,
            amount: this.$v.form.amount.$model,
            checkNumber: this.form?.checkNumber || null,
            batchJournalNumber: this.form?.batchJournalNumber || null,
          },
        });
      } catch (error) {
        console.error('Saving Invoice Payment', error);
      }
      this.handleClose();
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
  async mounted() {
    if (!this.invoiceDetailsPayment.invoicePayments.length) {
      this.$v.form.amount.$model = this.invoiceTotalCosts.total;
    }

    this.budgetCodes = await this.getBudgetCodes();
  },
  watch: {
    '$v.form.fundingSourceType.$model': function (newValue) {
      if (newValue === 'staff_cost') {
        this.$v.form.amount.$model = this.invoiceTotalCosts.staff;
      } else if (newValue === 'travel_cost') {
        this.$v.form.amount.$model = this.invoiceTotalCosts.travel;
      } else if (newValue === 'percentage') {
        this.$v.form.amount.$model = 100;
      }
    },
    selectedBudgetCode(newVal) {
      this.editedBudgetCode = newVal.description;
    },
    data: {
      immediate: true,
      handler(newData) {
        if (Object.keys(newData).length > 0) {
          this.form = { ...newData, fundingSource: newData.fundingSourceId };
        }
      },
    },
  },
};
</script>
