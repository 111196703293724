<template>
  <v-navigation-drawer
    v-model="showDrawer"
    absolute
    temporary
    :permanent="showDrawer"
    right
    :width="drawerWidth"
  >
    <v-toolbar flat>
      <InvoiceHeaderButtons v-if="showDrawer" :content="selectedInvoice" @close="$emit('close')" />
    </v-toolbar>
    <v-list class="pa-0">
      <v-list-item>
        <v-list-item-content class="pa-0">
          <v-list-item-title>
            <InvoiceDetails v-if="showDrawer" :content="selectedInvoice" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import InvoiceDetails from '@/components/Invoice/details/InvoiceDetails.vue';
import InvoiceHeaderButtons from '@/components/Invoice/details/InvoiceHeaderButtons.vue';
export default {
  components: {
    InvoiceDetails,
    InvoiceHeaderButtons,
  },
  data() {
    return {
      drawerWidth: '80vw',
      showDrawer: false,
    };
  },
  computed: {
    ...mapGetters('invoice', ['selectedInvoice']),
  },
  watch: {
    selectedInvoice: {
      immediate: true,
      handler: function (value) {
        this.showDrawer =  this.selectedInvoice ? true : false;
      },
    },
  },
};
</script>
