<template>
  <v-container fluid class="pa-0">
    <v-row class="pb-2" v-if="invoiceIsFetchingAdditionalCharge">
      <v-col cols="12">
        <v-progress-linear color="primary" absolute indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <div v-for="(additionalCharge, index) in invoiceAdditionalChargeList?.invoiceAdditionalCharge" :key="index">
      <div class="pa-4">
        <v-row dense>
          <v-col cols="10" class="pb-0">
            <v-combobox
              :disabled="invoiceReadOnly"
              v-model="additionalCharge.chargeType"
              :items="additionalChargeList"
              item-text="chargeType"
              return-object
              label="+ Enter New Cost Type"
              outlined
              @change="handleChangeChargeType($event, additionalCharge)"
              @update:search-input="handleSearchInput($event, additionalCharge)"
              :rules="[required]"
            ></v-combobox>
          </v-col>
          <v-col cols="2" class="pb-0 pl-4">
            <v-text-field
              :ref="'amountField_' + index"
              :disabled="invoiceReadOnly"
              placeholder="0.00"
              prefix="$"
              type="number"
              outlined
              v-model="additionalCharge.amount"
              @change="handleChange(additionalCharge)"
              :rules="[required, numeric, decimal]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="11" class="pt-0">
            <v-text-field
              :disabled="invoiceReadOnly"
              hide-details
              v-model="additionalCharge.description"
              @change="handleChange(additionalCharge)"
              flat
              solo
              placeholder="Enter description"
            ></v-text-field>
          </v-col>
          <v-col cols="1" v-if="!invoiceReadOnly">
            <v-btn fab text small color="red" @click="handleDeleteExistingAdditionalCharge(additionalCharge.id)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="divider mb-3"></v-divider>
    </div>
    <v-row no-gutters class="py-4">
      <v-col cols="3">
        <v-btn plain @click="addNewForm" v-if="!invoiceReadOnly" :loading="invoiceIsFetchingAdditionalCharge">
          <v-icon left>mdi-plus</v-icon>
          <span>Add</span>
        </v-btn>
      </v-col>
    </v-row>
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'Additional Costs'"
      :parentId="invoiceId"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>
<script>
import { inputProps } from '@/util';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required, numeric, decimal } from '@/util/rules';
import { CustomFormFieldsDisplay } from '@/components/shared';

export default {
  props: {
    invoiceId: { type: Number, default: 0 },
  },
  components: {
    CustomFormFieldsDisplay,
  },
  data() {
    return {
      required,
      numeric,
      decimal,
      inputProps,
      additionalChargeList: [],
    };
  },
  mounted() {
    this.init();
  },
  async beforeDestroy() {
    // save any new additional charge in the setup table
    const existingAdditionalCharge = new Set(
      this.additionalChargeList.filter((value) => !!value.chargeType).map((value) => value.chargeType)
    );
    const existingInvoiceAdditionalCharges = this.invoiceAdditionalChargeList?.invoiceAdditionalCharge || [];
    for (const invoiceAdditionalCharge of existingInvoiceAdditionalCharges) {
      if (!invoiceAdditionalCharge.chargeType || existingAdditionalCharge.has(invoiceAdditionalCharge.chargeType)) {
        continue;
      }

      await this.saveAdditionalInvoiceCost({
        chargeType: invoiceAdditionalCharge.chargeType,
        amount: invoiceAdditionalCharge.amount,
        description: invoiceAdditionalCharge.description,
      });
    }
  },
  computed: {
    ...mapGetters('additionalInvoiceCost', ['additionalInvoiceCosts']),
    ...mapGetters('invoice', [
      'invoiceReadOnly',
      'invoiceDetailsAdditionalCharge',
      'invoiceIsFetchingAdditionalCharge',
    ]),
    invoiceAdditionalChargeList() {
      if (this.invoiceDetailsAdditionalCharge?.invoiceAdditionalCharge) {
        return JSON.parse(JSON.stringify(this.invoiceDetailsAdditionalCharge));
      }
      return [];
    },
  },
  methods: {
    ...mapActions('additionalInvoiceCost', ['getAdditionalInvoiceCosts', 'saveAdditionalInvoiceCost']),
    ...mapActions('invoice', ['saveInvoiceAdditionalCharge', 'getInvoiceDetails']),
    ...mapMutations('invoice', ['setInvoiceDetails', 'addAdditionalCharge']),
    async init() {
      await this.getAdditionalInvoiceCosts();
      this.additionalChargeList = [{ header: 'Enter New Cost Type' }, ...this.additionalInvoiceCosts];
      await this.getInvoiceDetails({ tab: 'additional_charge', invoiceId: this.invoiceId });
    },
    async addNewForm() {
      await this.addAdditionalCharge({
        invoiceId: this.invoiceId,
      });
    },
    async handleChangeChargeType(e, newAdditionalCharge) {
      const additionalCharge = this.additionalChargeList.find((item) => item.chargeType === e.chargeType);
      if (e.id && additionalCharge) {
        newAdditionalCharge.chargeType = additionalCharge?.chargeType;
        newAdditionalCharge.amount = additionalCharge?.amount;
        newAdditionalCharge.description = additionalCharge?.description;
      }

      await this.handleChange(newAdditionalCharge);
    },
    handleSearchInput(searchInput, additionalCharge) {
      additionalCharge.chargeType = searchInput;
    },
    async handleChange(additionalCharge) {
      await this.attemptToSaveAdditionalCharge(additionalCharge);
    },
    async attemptToSaveAdditionalCharge(additionalCharge) {
      // ignore if we are missing fields.
      if (!additionalCharge.chargeType || !additionalCharge.amount) {
        return;
      }

      await this.saveInvoiceAdditionalCharge({
        body: {
          invoiceId: additionalCharge.invoiceId,
          invoiceAdditionalChargeId: additionalCharge.id,
          chargeType: additionalCharge.chargeType,
          description: additionalCharge.description,
          amount: additionalCharge.amount,
        },
        invoiceId: this.invoiceId,
      }).catch((error) => {
        this.$myalert.error(
          error.response?.data?.message ||
            error.message ||
            'Failed to update additional charge to invoice. Please try again.',
          true
        );
      });
    },
    handleDeleteExistingAdditionalCharge(additionalChargeId) {
      this.saveInvoiceAdditionalCharge({
        body: {
          invoiceAdditionalChargeId: additionalChargeId,
          deleted: true,
        },
        invoiceId: this.invoiceId,
      })
        .then(() => {
          this.$myalert.success('Additional Charge deleted successfully.', true);
        })
        .catch((error) => {
          this.$myalert.error(
            error.response?.data?.message ||
              error.message ||
              'Failed to delete additional charge to invoice. Please try again.',
            true
          );
        });
    },
  },
};
</script>
<style scoped>
.divider {
  background-color: #d3d3d3;
}
</style>
