<template>
  <v-menu
    v-if="invoiceArrayFilters.length"
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    min-width="350px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-capitalize" v-bind="attrs" v-on="on" :class="{ flash: filtersChanged }">
        <v-icon left>mdi-filter</v-icon>
        Active Filters ({{ invoiceArrayFilters.length }})
      </v-btn>
    </template>

    <v-card class="card-content">
      <v-list class="active-filter-field">
        <Chip
          v-for="(filter, index) in invoiceArrayFilters"
          :key="index"
          close
          @click:close="removeFilter(filter)"
          class="chips"
          :disabled="isInvoiceFilterDisabled"
          wrap
        >
          {{ filter.text }}
        </Chip>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions class="card-actions">
        <v-btn :disabled="isInvoiceFilterDisabled" color="#ff4242" text @click="removeFilter('all')"> Clear All </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Chip } from '@/components/shared';

export default {
  computed: {
    ...mapGetters('invoice', ['invoiceFilters', 'invoiceArrayFilters', 'isInvoiceFilterDisabled']),
    ...mapGetters('app', ['isMobile']),
  },
  components: { Chip },
  watch: {
    invoiceArrayFilters(filters) {
      if (!filters.length) this.showMenu = false;

      this.filtersChanged = true;
      setTimeout(() => {
        this.filtersChanged = false;
      }, 500);
    },
  },
  data() {
    return {
      showMenu: false,
      filtersChanged: false,
      statusFilters: ['Pending', 'Accepted', 'Declined', 'Canceled'],
      leaveDateFilters: ['Date Range', 'Today', 'Upcoming'],
    };
  },
  methods: {
    ...mapActions('invoice', ['getInvoices']),
    ...mapMutations('invoice', ['setInvoiceFilters']),
    removeFilter(filter) {
      if (this.isInvoiceFilterDisabled || !filter) {
        return;
      }

      if (filter === 'all') {
        this.setInvoiceFilters({});
        this.getInvoices();
        return;
      }

      const newFilters = { ...this.invoiceFilters };
      const currentFilterValue = newFilters[filter.field];

      if (filter.multiple) {
        newFilters[filter.field] = currentFilterValue.filter((value) => value !== filter.value);
      } else {
        delete newFilters[filter.field];
      }

      this.setInvoiceFilters(newFilters);
      this.getInvoices();
    },
  },
};
</script>
<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
  align-items: center;
}
.focused-search {
  width: calc(100vw - 351px);
  position: absolute;
  z-index: 200;
  background-color: #f5f5f5;
  height: 100%;
  align-items: center;
}
.w-full {
  width: 100%;
}
.card-content {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.active-filter-field {
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-actions {
  display: flex;
  justify-content: center;
}
::v-deep .chips {
  margin: 5px;
  border: 2px solid #59c129;
}
::v-deep .chips .v-chip:not(.v-chip--active) {
  background: #f5f5f5;
}
::v-deep .chips .v-chip__close {
  color: #ff4242;
}
.flash {
  animation: flashAnimation 0.5s ease-in-out;
}
@keyframes flashAnimation {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
