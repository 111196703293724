<template>
  <v-container fluid class="pa-0">
    <div class="d-flex align-center">
      <div class="mr-auto">
        <v-select
          class="invoice-status"
          chips
          :disabled="!canUpdateInvoiceStatus(status.id)"
          :hide-details="true"
          :hide-selected="true"
          :items="getFilteredInvoiceStatusOptions(content)"
          :value="status.id"
          @change="onChipStatusSelect($event)"
        >
          <template v-slot:selection>
            <v-chip :color="status.color" class="font-weight-bold" label>
              {{ status.text }}
              <v-avatar right v-if="canUpdateInvoiceStatus(status.id)">
                <v-icon>mdi-chevron-down</v-icon>
              </v-avatar>
            </v-chip>
          </template>
        </v-select>
      </div>
      <div class="d-flex align-center">
        <upload-file
          ref="uploadFile"
          recordType="invoice"
          :recordId="content.id"
          showSize
          persistentHint
          :hideInput="true"
          :readonly="false"
          @onUpload="onUpload"
        ></upload-file>
        <v-btn @click="handlePrint" icon class="mx-2" :loading="isPrinting">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn @click="handleDownload" icon class="mx-2">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import UploadFile from '@/components/UploadFile.vue';
export default {
  props: { content: { type: Object, default: () => ({}) } },
  computed: {
    ...mapGetters('invoice', [
      'statusConfig',
      'getFilteredInvoiceStatusOptions',
      'invoiceReadOnly',
      'canUpdateInvoiceStatus',
    ]),
    status() {
      return {
        id: this.content.status,
        text: this.statusConfig[this.content.status] ? this.statusConfig[this.content.status].text : '',
        color: this.statusConfig[this.content.status] ? this.statusConfig[this.content.status].color : 'grey',
      };
    },
  },
  components: { UploadFile },
  watch: {
    file: function (nFile, _) {
      if (nFile !== null) this.addAttachment();
    },
  },
  data() {
    return {
      file: null,
      isPrinting: false,
    };
  },
  methods: {
    ...mapActions('invoice', ['getAttachments', 'saveInvoice', 'printInvoices']),
    async addAttachment() {
      this.file = null;
    },
    async onUpload(val) {
      await this.getAttachments(this.content.id);
    },
    async onChipStatusSelect(value) {
      this.saveInvoice({
        invoiceId: this.content.id,
        body: { status: value },
      });
    },
    async handlePrint() {
      this.isPrinting = true;
      try {
        if (!this.content.id) {
          this.$myalert.error('No selected invoice', true);
          return;
        }

        await this.printInvoices({ invoiceIds: [this.content.id] });
        this.$myalert.success(
          'Your print request has been successfully processed. A PDF file will be generated and downloaded to your device shortly.',
          true
        );
      } catch (error) {
        this.$myalert.error('An error occurred while processing your print request.', true);
      } finally {
        this.isPrinting = false;
      }
    },
    handleSave() {
      console.log('save');
    },
    handleDownload() {
      console.log('download');
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
